import React from 'react';
import PropTypes from 'prop-types';
import Styles from './DataGrid.module.scss';
import { FaCheckSquare, FaRegSquare } from 'react-icons/fa';
import { observer } from 'mobx-react';
import { NumericFormat } from 'react-number-format';
import dayjs from 'dayjs';

/**
 * 데이터그리드 셀
 */
@observer
class Cell extends React.Component {
  constructor(props) {
    super(props);
  }

  /**
   * 셀의 값을 출력 한다. (혹은 포맷팅 한다.)
   * @returns {*}
   */
  toString(currentWidth) {
    const { column, printFunction, cellData, options } = this.props;
    if (column.type && !printFunction && cellData) {
      if (column.type === 'check')
        return cellData ? <FaCheckSquare /> : <FaRegSquare />;

      if (column.type === 'checkNy')
        return cellData === 'Y' ? <FaRegSquare /> : <FaCheckSquare />;

      if (column.type === 'checkYn')
        return cellData === 'Y' ? <FaCheckSquare /> : <FaRegSquare />;

      if (column.type === 'date') {
        return cellData && <div>{dayjs(cellData).format('YYYY-MM-DD')}</div>;
      }

      if (column.type === 'datetime') {
        return (
          cellData && <div>{dayjs(cellData).format('YYYY-MM-DD HH:mm:ss')}</div>
        );
      }

      if (column.type === 'time') {
        return cellData && <div>{dayjs(cellData).format('HH:mm:ss')}</div>;
      }

      if (column.type === 'currency')
        return (
          <NumericFormat
            value={cellData}
            displayType="text"
            thousandSeparator={false}
          />
        );

      if (column.type === 'number')
        return (
          <NumericFormat
            value={cellData}
            displayType="text"
            thousandSeparator={true}
          />
        );

      if (column.type === 'select')
        return options.find((option) => option.value === cellData)?.label;
    } else if (column.type && !printFunction && !cellData) {
      if (column.type === 'date') {
        return <div>-</div>;
      }
    }

    // props 의 labelFunction 키가 라벨링 함수를 가지고 있을거라 간주.
    return !!this.props.printFunction
      ? this.props.printFunction(
          this.props.cellData,
          this.props.rowData,
          currentWidth,
        )
      : this.props.cellData;
  }

  render() {
    let {
      store,
      id,
      column,
      onClick,
      //   rowData,
      //   cellData,
      //   errorMessage,
      //   status,
      //   isModifiable,
    } = this.props;
    let width = store?.colMap?.get(id) || store.defaultWidth;
    let {
      // min = 60, max = 200,
      current = 100,
    } = width;
    return (
      // eslint-disable-next-line react/no-unknown-property
      <td
        nowrap="true"
        className={`${Styles.Cell}`}
        style={column.style}
        onClick={() => {
          onClick && onClick(this.props);
        }}
      >
        <div
          className={`d-flex flex-default ${column.className}`}
          style={{ width: current, ...column.style }}
        >
          <div>{this.toString(current)}</div>
        </div>
      </td>
    );
  }
}

Cell.propTypes = {
  /**
   * 셀의 값
   */
  cellData: PropTypes.any,

  /**
   * 행의 데이터
   */
  rowData: PropTypes.object,

  /**
   * 출력함수
   */
  printFunction: PropTypes.func,

  /**
   *
   */
  column: PropTypes.any.isRequired,
};

Cell.defaultProps = {
  options: [],
};

export default Cell;
