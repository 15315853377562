import React from 'react';
import Styles from './ScrollToBottomButton.module.scss';
import { observer } from 'mobx-react';
import { ArrowDown2 } from 'iconsax-react';
import CustomButton from '../button/CustomButton.js';
import Avatar from './avatar/Avatar.js';
import ContractionMessage from './message/ContractionMessage.js';
import classNames from 'classnames';
import { TICKET_STATUS } from '../../const/ChatConst.js';

@observer
class ScrollToBottomButton extends React.Component {
  //   componentDidMount() {
  //     let { store } = this.props;
  //   }

  render() {
    let { store, isUser } = this.props;
    let { isBottom, isNewMessage, lastUserMessage } = store;
    const isNew = isNewMessage && lastUserMessage;

    return (
      <div
        className={classNames(
          Styles.ScrollToBottom,
          (isUser || store.channel.status === TICKET_STATUS.CLOSED) &&
            Styles.isUser,
        )}
      >
        <CustomButton
          className={`${Styles.Button} ${!isBottom ? Styles.isNotBottom : ''} ${isNew ? Styles.isNew : ''}`}
          onClick={() => store.debounceScrollToBottom()}
        >
          {isNew && (
            <div className={Styles.LastMessage}>
              {lastUserMessage?.sender && (
                <div className={Styles.Sender}>
                  <div className={Styles.Avatar}>
                    <Avatar
                      type={lastUserMessage?.sender?.type}
                      src={lastUserMessage?.sender?.avatar?.fullUrl}
                      seed={lastUserMessage?.sender?.id}
                    />
                  </div>
                  {lastUserMessage?.sender?.name}
                </div>
              )}
              <div className={Styles.Content}>
                <ContractionMessage message={lastUserMessage} />
              </div>
            </div>
          )}
          <div className={Styles.Icon}>
            <ArrowDown2 size={18} />
          </div>
        </CustomButton>
      </div>
    );
  }
}

export { ScrollToBottomButton };
