import React, { Component } from 'react';

import {
  channelStore,
  chatStore,
  quickMsgStore,
  rSocket,
  ticketStore,
} from '../store/FrontChatStore';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import Cookies from 'js-cookie';
import { v4 as uuid } from 'uuid';
import Select from 'react-select';
import Styles from './TestView.module.scss';
import { ADJECTIVES, ANIMALS } from '@qbot-chat/qbot-uikit';

import {
  COOKIE_KEY_CHANNEL_ID,
  COOKIE_KEY_USER_ID,
  COOKIE_KEY_USER_KEY,
  COOKIE_KEY_SCENARIO_YN,
  COOKIE_KEY_TEL_NO,
} from '../const/AppConst';

import { navigateReplace } from '../helper/HistoryHelper';
import { observer } from 'mobx-react';

@observer
class TestView extends Component {
  CHAT_TYPE = {
    CHAT: 'chat',
    VOICE: 'voice',
  };

  pagingOption = {
    pagingOption: {
      page: 1,
      rowsPerPage: 10,
      sort: {
        id: 'id',
        direction: 'DESC',
      },
    },
    status: '',
    keyword: '',
  };

  compList = {};

  constructor(props) {
    super(props);
    this.state = {
      chatType: this.CHAT_TYPE.VOICE,
      companyList: [],
      selCompTel: '',
    };
  }

  componentDidMount() {
    if (this.state.companyList.length == 0) {
      axios.defaults.baseURL = '/api/v1';
      axios.post('/company/list', this.pagingOption).then((res) => {
        let datas = res.data.items;
        console.log(datas);
        for (let data of datas) {
          data.label = data.companyName;
          data.value = data.id;
        }
        this.setState({ companyList: res.data.items });
      });
    }
  }

  handleRadioChange = (e) => {
    this.setState({ chatType: e.target.id });
  };

  onCompanyChange = (e) => {
    this.setState({ selCompTel: e.companyTel });
  };

  clickStartBtn = async () => {
    if (this.state.selCompTel == '') {
      alert(
        '상담할 회사를 선택해주세요. 혹은 선택한 회사의 전화번호가 존재하지 않습니다. ',
      );
      return false;
    }

    if (this.state.chatType == this.CHAT_TYPE.CHAT) {
      this.procChat();
    } else {
      this.procVoice();
    }
  };

  procChat = async () => {
    Cookies.set(COOKIE_KEY_SCENARIO_YN, 'N');

    // // session 생성
    // let session = await axios.post("/user/issue-session", {});

    // axios.defaults.headers = {
    //     'x-qbot-session': session.headers['x-qbot-session']
    // }

    let user_key = 'a5998acb-99f0-49ca-a2c5-e158ec4cc03a';

    const { data: customer } = await rSocket.requestChatApi(`/user/search`, {
      key: user_key,
      name: `${ADJECTIVES[Math.floor(Math.random() * ADJECTIVES.length)]} ${
        ANIMALS[Math.floor(Math.random() * ANIMALS.length)]
      }`,
    });

    Cookies.set(COOKIE_KEY_USER_ID, customer.id);
    Cookies.set(COOKIE_KEY_USER_KEY, user_key);

    console.log(customer);

    await rSocket.run(
      {
        sender: customer,
        ticket: ticketStore,
        channel: channelStore,
        chat: chatStore,
      },
      () => {
        quickMsgStore.setRSocketStore(rSocket);
        chatStore.setQuickMsgStore(quickMsgStore);
      },
    );

    let params = {
      inflowType: this.state.chatType == 'voice' ? 'ARS' : 'CHAT',
      // userId:'SbMRn5Y5w/g93xLLoM1ZJQ==',
      userKey: user_key,
      userId: Cookies.get(COOKIE_KEY_USER_ID),
      chatBotType: this.state.selCompTel,
    };

    Cookies.set(COOKIE_KEY_TEL_NO, this.state.selCompTel);

    axios.defaults.baseURL = '/mp/api/v1';
    let res = await axios.post('/channel-log/append', params);

    console.log(res);
    Cookies.set(COOKIE_KEY_CHANNEL_ID, res.data.channelId);
    //window.location.href = "/loading";
    navigateReplace('/loading/false');
  };

  procVoice = async () => {
    Cookies.set(COOKIE_KEY_SCENARIO_YN, 'Y');

    axios.defaults.baseURL = '/chat/api/v1';

    // session 생성
    let session = await axios.post('/user/issue-session', {});

    axios.defaults.headers = {
      'x-qbot-session': session.headers['x-qbot-session'],
    };

    console.log(session);

    let userKey = uuid();
    Cookies.set(COOKIE_KEY_USER_KEY, this.userKey);

    // user생성
    let user = await axios.post('/user/create', {
      id: 'DUMMY',
      name: 'TESTBOT',
      type: 'CUSTOMER',
      key: userKey,
    });

    console.log(user);

    await rSocket.run(
      {
        sender: user.data,
        ticket: ticketStore,
        channel: channelStore,
        chat: chatStore,
      },
      () => {
        quickMsgStore.setRSocketStore(rSocket);
        chatStore.setQuickMsgStore(quickMsgStore);
      },
    );

    let user_id = user.data.id;

    Cookies.set(COOKIE_KEY_USER_ID, user_id);

    let params = {
      inflowType: this.state.chatType == 'voice' ? 'ARS' : 'CHAT',
      // userId:'SbMRn5Y5w/g93xLLoM1ZJQ==',
      userKey: user.data.key,
      userId: Cookies.get(COOKIE_KEY_USER_ID),
      chatBotType: this.state.selCompTel,
    };
    Cookies.set(COOKIE_KEY_TEL_NO, this.state.selCompTel);

    axios.defaults.baseURL = '/mp/api/v1';
    let chanLog = await axios.post('/channel-log/append', params);

    Cookies.set(COOKIE_KEY_CHANNEL_ID, chanLog.data.channelId);
    console.log(chanLog);

    navigateReplace('/loading/false');
  };

  render() {
    return (
      <div className={`d-flex flex-default overflow-hidden`}>
        <div>
          <Form className={Styles.chat_type} onSubmit={this.hendleFormOnSubmit}>
            <div key={`inline-radio`} className="mb-3">
              <Form.Check
                inline
                label="채팅봇"
                name="group1"
                type="radio"
                id={`chat`}
                onChange={(e) => this.handleRadioChange(e)}
              />
              <Form.Check
                inline
                label="음성봇"
                name="group1"
                type="radio"
                id={`voice`}
                checked
                onChange={(e) => this.handleRadioChange(e)}
              />
            </div>
            <Select
              options={this.state.companyList}
              onChange={this.onCompanyChange}
            />
            <br></br>
          </Form>
          <Button variant="primary" onClick={this.clickStartBtn}>
            상담시작
          </Button>
        </div>
      </div>
    );
  }
}

export default TestView;
