import React from 'react';
import Styles from './Header.module.scss';
import { Button, Dropdown } from 'react-bootstrap';
import { FormControl } from 'react-bootstrap';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { ChatStore } from '../../store/ChatStore.js';
import {
  ArrowDown2,
  ArrowUp2,
  HambergerMenu,
  SearchNormal1,
} from 'iconsax-react';
import CustomButton from '../button/CustomButton.js';
import {
  COMMAND_EVENT_TYPE,
  CONTENT_TYPE,
  TICKET_STATUS,
  USER_TYPE,
} from '../../const/ChatConst.js';
import Avatar from './avatar/Avatar.js';
import dayjs from 'dayjs';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <CustomButton className={Styles.RequestButton}>{children}</CustomButton>
  </div>
));

@observer
class Header extends React.Component {
  render() {
    let {
      store,
      backButton,
      readOnly,
      renderDropDownMenu,
      renderCustomHeader,
    } = this.props;
    let { channel, sender } = store;
    let { assignment, customer } = channel;
    let agent = assignment?.agent;
    let opponent = sender?.type == USER_TYPE.CUSTOMER ? agent : customer;
    return (
      <>
        {renderCustomHeader ? (
          renderCustomHeader(store)
        ) : (
          <div className={Styles.Header}>
            <div className={Styles.Left}>
              {backButton}
              <div className={Styles.Avatar}>
                <Avatar
                  type={opponent?.type}
                  src={opponent?.avatar?.fullUrl}
                  seed={opponent?.id}
                />
              </div>
              <div className={Styles.Sender}>{opponent?.name}</div>
            </div>

            <div className={Styles.Right}>
              <CustomButton
                className={Styles.RequestButton}
                onClick={() => {
                  store.toggleIsSearchMode();
                }}
              >
                <SearchNormal1 size={20} />
              </CustomButton>
              {store?.channel?.status != TICKET_STATUS.CLOSED && (
                <>
                  {sender?.type == USER_TYPE.CUSTOMER ? (
                    <>
                      {!store.isRequesting && (
                        <Button
                          variant={'outline-secondary'}
                          className={Styles.RequestButton}
                          style={{ whiteSpace: 'nowrap' }}
                          size={'sm'}
                          onClick={() => {
                            store.isRequested = true;
                            let contentType = CONTENT_TYPE.TEXT;
                            let message = {
                              content: {
                                type: contentType,
                                value: COMMAND_EVENT_TYPE.REQUEST_AGENT,
                              },
                              contentType,
                              createdAt: dayjs(),
                            };
                            store.onSendMessage(message);
                          }}
                        >
                          상담사 연결
                        </Button>
                      )}
                    </>
                  ) : (
                    <Dropdown autoClose={true}>
                      {!readOnly && renderDropDownMenu && (
                        <Dropdown.Toggle as={CustomToggle}>
                          <HambergerMenu size={20} />
                        </Dropdown.Toggle>
                      )}

                      <Dropdown.Menu>
                        {renderDropDownMenu && renderDropDownMenu(store)}
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </>
              )}
            </div>
            <div
              className={`${Styles.SearchHeader} ${store.isSearchMode ? Styles.IsOpen : ''}`}
            >
              <div className={Styles.Search}>
                <SearchNormal1 size={20} color={`var(--bs-secondary)`} />
              </div>
              <div className={Styles.Input}>
                <FormControl
                  placeholder={'대화내용 검색'}
                  onKeyDown={(e) => {
                    if (e.key == 'Enter') {
                      store.initSearchMessage(e.target.value);
                    }
                  }}
                />
              </div>
              {store.searchedMessages?.length > 0 && (
                <div
                  className={`d-flex justify-center align-items-center pe-3`}
                >
                  <CustomButton
                    disabled={!store.isPrevSearchable}
                    className={`me-1`}
                    onClick={() => {
                      store.prevSearchMessage();
                    }}
                  >
                    <ArrowUp2 size={20} />
                  </CustomButton>
                  <CustomButton
                    disabled={!store.isNextSearchable}
                    onClick={() => {
                      store.nextSearchMessage();
                    }}
                  >
                    <ArrowDown2 size={20} />
                  </CustomButton>
                </div>
              )}
              <Button
                className={Styles.Cancel}
                variant={`outline-secondary`}
                onClick={() => {
                  store.toggleIsSearchMode();
                }}
              >
                취소
              </Button>
            </div>
          </div>
        )}
      </>
    );
  }
}

Header.defaultProps = {};

Header.propType = {
  store: PropTypes.instanceOf(ChatStore),
  dropDownMenus: PropTypes.array,
};

export { Header };
