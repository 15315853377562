import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Styles from './QuickMsg.module.scss';
import InfiniteScroll from 'react-infinite-scroller';
import { AddSquare, MessageAdd } from 'iconsax-react';
import { Button, Tag, Tooltip } from 'antd';
import { Spinner } from 'react-bootstrap';

const TYPE = {
  COMMON: '공통',
  PERSONAL: '개인',
};

const ColoredItem = ({ item, query }) => {
  return query && item.includes(query) ? (
    <>
      {item.split(query).length > 2 ? (
        item.split(query).map((text, idx) => {
          return (
            item.split(query).length > 2 &&
            idx < item.split(query).length - 1 && (
              <span key={idx}>
                {text}
                <span style={{ color: '#0BB7AF' }}>{query}</span>
              </span>
            )
          );
        })
      ) : (
        <>
          {item.split(query)[0]}
          <span style={{ color: '#0BB7AF' }}>{query}</span>
          {item.split(query)[1]}
        </>
      )}
    </>
  ) : (
    <>{item}</>
  );
};

@observer
class QuickMsg extends Component {
  constructor(props) {
    super(props);
  }

  keyboardNavigation = (e) => {
    const { store } = this.props;
    const quickMsgStore = store.quickMsgStore;
    if (
      !quickMsgStore ||
      !quickMsgStore.quickMsgList.length > 0 ||
      !quickMsgStore.isOpen
    )
      return;

    if (e.key === 'ArrowDown') {
      quickMsgStore.setCursor(
        quickMsgStore.cursor < quickMsgStore.quickMsgList.length - 1
          ? quickMsgStore.cursor + 1
          : quickMsgStore.cursor,
      );
      quickMsgStore.elmRefs[quickMsgStore.cursor].current?.scrollIntoView();
    }
    if (e.key === 'ArrowUp') {
      quickMsgStore.setCursor(
        quickMsgStore.cursor > 0 ? quickMsgStore.cursor - 1 : 0,
      );
      quickMsgStore.elmRefs[quickMsgStore.cursor].current?.scrollIntoView();
    }
    if (e.key === 'Escape') {
      quickMsgStore.setCursor(-1);
      quickMsgStore.setIsOpen(false);
    }
    if (e.key === 'Enter' && quickMsgStore.cursor > -1) {
      this.onSend(quickMsgStore.quickMsgList[quickMsgStore.cursor].content);
    }
  };

  componentDidMount() {
    window.addEventListener('keydown', this.keyboardNavigation);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.keyboardNavigation);
  }

  mouseEnter = (index) => {
    const { store } = this.props;
    store.quickMsgStore.setCursor(index);
  };

  onSend = (content) => {
    const { store } = this.props;
    store.quickMsgFn(content);
    store.quickMsgStore.setCursor(-1);
    store.quickMsgStore.setIsOpen(false);
    store.debounceScrollToBottom();
  };

  render() {
    const { store } = this.props;

    return (
      <div className={Styles.QuickMsg}>
        <div
          className={Styles.ContentContainer}
          style={{ height: store.quickMsgStore.isOpen ? '288px' : 0 }}
        >
          <div className={Styles.Header}>
            <span>#빠른답변</span>
            <div className={'d-flex'}>
              <Button
                onClick={() => {
                  store.quickMsgFn('');
                  store.quickMsgStore.setIsPopupOpen(true);
                }}
                type="text"
                size="small"
                className={Styles.addButton}
                icon={<AddSquare size="15" color="#FFFFFF" variant="Outline" />}
              >
                빠른답변 추가
              </Button>
            </div>
          </div>
          <div
            className={Styles.Body}
            ref={store.quickMsgStore.scrollRef}
            onScroll={store.quickMsgStore.onScrollHandler}
          >
            <div className={Styles.ScrollContainer}>
              <InfiniteScroll
                key={`infinite`}
                pageStart={0}
                loadMore={() => {
                  store.quickMsgStore.loadMore();
                }}
                hasMore={store.quickMsgStore.hasMore}
                initialLoad
                useWindow={false}
                threshold={10}
                getScrollParent={() => store.quickMsgStore.scrollRef.current}
                loader={
                  <div className={Styles.Loader} key={'loader'}>
                    <Spinner animation="border" variant="secondary" size="sm" />
                  </div>
                }
              >
                {store.quickMsgStore.quickMsgList.length > 0 ? (
                  store.quickMsgStore.quickMsgList.map((msg, idx) => {
                    return (
                      <div
                        key={idx}
                        className={Styles.MsgContainer}
                        ref={store.quickMsgStore.elmRefs[idx]}
                        style={{
                          backgroundColor:
                            store.quickMsgStore.cursor === idx
                              ? '#EEF5F9'
                              : '#FFFFFF',
                        }}
                        onMouseEnter={() => this.mouseEnter(idx)}
                        onClick={() => {
                          this.onSend(msg.content);
                        }}
                      >
                        <Tag
                          color={
                            msg.quickMsgType === 'COMMON' ? 'blue' : 'geekblue'
                          }
                        >
                          {TYPE[msg.quickMsgType]}
                        </Tag>
                        <div className={Styles.MsgTitle}>
                          <ColoredItem
                            item={msg.title}
                            query={store.quickMsgStore.keyword}
                          />
                        </div>
                        <div className={Styles.MsgContent}>
                          <Tooltip title={msg.content}>
                            <p>
                              <ColoredItem
                                item={msg.content}
                                query={store.quickMsgStore.keyword}
                              />
                            </p>
                          </Tooltip>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className={Styles.NoMsg}>
                    <p>등록된 빠른 답변이 없습니다.</p>
                    <p>아래 버튼을 눌러 빠른 답변을 등록 해 주세요.</p>

                    <Button
                      onClick={() => {
                        store.quickMsgFn('');
                        store.quickMsgStore.setIsPopupOpen(true);
                      }}
                      size="small"
                      type="primary"
                      icon={<MessageAdd size="16" />}
                    >
                      빠른답변 등록
                    </Button>
                  </div>
                )}
              </InfiniteScroll>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default QuickMsg;
