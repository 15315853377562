import React, { Component } from 'react';
import Styles from './App.module.scss';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { observer } from 'mobx-react';
import { HISTORY } from './helper/HistoryHelper';
import Loading from './view/loading/Loading';
import ChatView from './view/ChatView';
import CloseView from './view/CloseView';
import RatingView from './view/RatingView';
import TestView from './view/TestView';
import Maintenance from './view/maintenance/Maintenance';
import Expired from './view/close/Expired';
import InitView from './view/InitView';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';

@observer
class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={`d-flex flex-default ${Styles.Content}`}>
        <Router history={HISTORY}>
          <Switch>
            <Route path="/init" component={InitView} />
            <Route path="/test" component={TestView} />
            <Route path="/loading/:fromLanding?" component={Loading} />
            <Route path="/chat/:channelId" component={ChatView} />
            <Route path="/close" component={CloseView} />
            <Route path="/rating/:channelId" component={RatingView} />
            <Route path="/maintenance" component={Maintenance} />
            <Redirect
              exact
              from="/"
              to={{
                pathname: '/loading',
                search: '',
              }}
              push={false}
            />
            <Route component={Expired} />
          </Switch>
        </Router>
        <NotificationContainer />
      </div>
    );
  }
}

export default App;
