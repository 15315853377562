import { ADJECTIVES, ANIMALS } from '../const/ChatConst.js';
import { Buffer } from 'buffer';

class CommonHelper {
  static checkOuterUrl(url) {
    if (
      /^((http(s?))\:\/\/)([0-9a-zA-Z\-]+\.)+[a-zA-Z]{2,6}(\:[0-9]+)?(\/\S*)?$/.test(
        url,
      ) ||
      url?.startsWith('data:image')
    ) {
      return true;
    } else {
      return false;
    }
  }

  static groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  static range(a, b) {
    let result = [];
    for (let i = a; i < b; i++) {
      result.push(i);
    }
    return result;
  }

  static findKeys(m, ...keys) {
    let result = {};
    m.forEach((v, k) => keys.includes(k) && (result[k] = v));
    return result;
  }

  static omit(obj, ...keys) {
    let result = Object.assign({}, obj);
    keys.forEach((key) => delete result[key]);
    return result;
  }

  static clearValue(m) {
    m.keys().forEach((k) => m.set(k, ''));
  }

  static mapToObject(m) {
    let obj = {};
    m.forEach((v, k) => {
      obj[k] = v;
    });
    return obj;
  }

  static copyPropertiesToMap(obj, m) {
    Object.entries(obj).forEach(([k, v]) => m.set(k, v));
  }

  static objectToMap(obj) {
    let m = new Map();
    CommonHelper.copyPropertiesToMap(obj, m);
    return m;
  }

  static urlHostname(data) {
    let a = document.createElement('a');
    a.href = data;
    return a.hostname;
  }

  static arrayMove(arr, oldIndex, newIndex) {
    if (newIndex >= arr.length) {
      let k = newIndex - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  }

  static serialize(obj) {
    return Buffer.from(JSON.stringify(obj));
  }

  static deserialize(buffer) {
    return JSON.parse(Buffer.from(buffer).toString());
  }

  static generateMetadata(route) {
    return Buffer.concat([
      Buffer.from(String.fromCharCode(route.length)),
      Buffer.from(route),
    ]);
  }

  static parseBlocks(blocks, flag) {
    if (flag) {
      // make block
      const { html } = blocks;

      // console.log(html);
      const newString = html
        // .replace(/<[div][^>]*>/g, '<div>')
        .replace(/&nbsp;/g, ' ')
        .replace(/<[^>]*>/g, '')
        .replace(/&lt;/gi, '<')
        .replace(/&gt;/gi, '>');

      // console.log(newString);
      return newString;
    } else {
      // copy
      let newArr = blocks.map(({ html }) => {
        const temp = html
          .replace(/<div>/gi, '\n')
          .replace(/&nbsp;/gi, ' ')
          .replace(/<[^>]*>/g, '');
        return temp;
      });
      const parsedString = newArr.join('\n\n');
      return parsedString;
    }
  }

  static getSelection(element) {
    let selectionStart, selectionEnd;
    const isSupported = typeof window.getSelection !== 'undefined';
    if (isSupported) {
      const range = window.getSelection()?.getRangeAt(0);
      const preSelectionRange = range.cloneRange();
      preSelectionRange.selectNodeContents(element);
      preSelectionRange.setEnd(range.startContainer, range.startOffset);
      selectionStart = preSelectionRange.toString().length;
      selectionEnd = selectionStart + range.toString().length;
    }
    return { selectionStart, selectionEnd };
  }

  static setCaretToEnd(element) {
    const range = document.createRange();
    const selection = window.getSelection();
    range.selectNodeContents(element);
    range.collapse(false);
    selection.removeAllRanges();
    selection.addRange(range);
    element.focus();
  }

  static getByteSize(size) {
    const byteUnits = ['KB', 'MB', 'GB', 'TB'];

    for (let i = 0; i < byteUnits.length; i++) {
      size = Math.floor(size / 1024);

      if (size < 1024) return size.toFixed(1) + byteUnits[i];
    }
  }

  static getUserName() {
    return `${ADJECTIVES[Math.floor(Math.random() * ADJECTIVES.length)]} ${ANIMALS[Math.floor(Math.random() * ANIMALS.length)]}`;
  }
}

export default CommonHelper;
