import React from 'react';
import Styles from './DateLine.module.scss';
import dayjs from 'dayjs';

class DateLine extends React.Component {
  render() {
    let { createdAt } = this.props;
    return (
      <div className={Styles.DateLine}>
        <span className={Styles.Message}>
          {dayjs(createdAt).format('ll dddd')}
        </span>
      </div>
    );
  }
}

export { DateLine };
