import React, { Component } from 'react';

import { Chat, confirmDialogStore, TICKET_STATUS } from '@qbot-chat/qbot-uikit';
import { chatStore, rSocket } from '../store/FrontChatStore';
import { navigateReplace } from '../helper/HistoryHelper';
import { Button, Spinner } from 'react-bootstrap';
import { action, makeObservable, observable } from 'mobx';
import Styles from './ChatView.module.scss';
import { MdClear } from 'react-icons/md';
import { COMMAND_EVENT_TYPE, CONTENT_TYPE } from '@qbot-chat/qbot-uikit/chat';
import { debounce } from 'lodash';
import { observer } from 'mobx-react';
import dayjs from 'dayjs';

@observer
class ChatView extends Component {
  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @observable
  storeInfo;

  @action.bound
  async init() {
    let {
      match: {
        params: { channelId },
      },
    } = this.props;

    if (rSocket.isReady) {
      chatStore
        .setChannel({ id: channelId, status: TICKET_STATUS.ACTIVE })
        .then((r) => {
          let status = chatStore?.channel?.status;
          if (status == TICKET_STATUS.CLOSED) {
            navigateReplace('/close?isRating=false');
          }
        })
        .catch((e) => {
          navigateReplace(`/maintenance`);
        });

      return;
    }
    console.log('우선 loading으로');
    navigateReplace('/loading');
  }

  componentDidMount() {
    this.init().then();
  }

  debounceRequest = debounce((store) => this.request(store), 200, {
    leading: true,
  });

  request = async (store) => {
    let contentType = CONTENT_TYPE.TEXT;
    let message = {
      content: {
        type: contentType,
        value: COMMAND_EVENT_TYPE.REQUEST_AGENT,
      },
      contentType,
      createdAt: dayjs(),
    };
    store.onSendMessage(message);
  };

  render() {
    // let storeInfo = this.storeInfo;
    return (
      <div className={`d-flex flex-default overflow-hidden`}>
        {rSocket.isReady ? (
          <Chat
            store={chatStore}
            isUser
            ref={chatStore.chatRef}
            renderCustomHeader={(store) => {
              return (
                <div className={Styles.HeaderContainer}>
                  <div className={Styles.Header}>
                    <div>
                      {!store.isRespond && (
                        <div
                          className={Styles.CloseButton}
                          onClick={() => {
                            confirmDialogStore.open({
                              title: '종료',
                              summary: '상담을 종료하시겠습니까?',
                              onOk: () => {
                                store.close(store.channelId).then(() => {
                                  navigateReplace(`/rating/${store.channelId}`);
                                });
                              },
                            });
                          }}
                        >
                          <MdClear size={24} />
                        </div>
                      )}
                    </div>
                    {/* <div className={Styles.Center}>
                      <div className={`${Styles.Badge} ${storeInfo?.jobType === 'OP' ? Styles.Active : ''}`}>{storeInfo?.jobType === 'OP' ? '영업중' : storeInfo?.jobType === 'PR' ? '영업준비중' : storeInfo?.jobType === 'CL' ? '영업종료' : '휴점일'}</div>
                      <div className={Styles.Title}>{storeInfo?.storeName || '...'}</div>
                    </div> */}
                    <div>
                      {!store.isRespond ? (
                        <>
                          {store.channel?.status !== TICKET_STATUS.CLOSED && (
                            <Button
                              variant={'outline-secondary'}
                              className={Styles.RequestButton}
                              style={{ whiteSpace: 'nowrap' }}
                              size={'sm'}
                              onClick={() => {
                                this.debounceRequest(store);
                              }}
                            >
                              상담사 연결
                            </Button>
                          )}
                        </>
                      ) : (
                        <Button
                          variant={'outline-danger'}
                          className={Styles.RequestButton}
                          style={{ whiteSpace: 'nowrap' }}
                          size={'sm'}
                          onClick={() => {
                            confirmDialogStore.open({
                              title: '종료',
                              summary: '상담을 종료하시겠습니까?',
                              onOk: () => {
                                store.close(store.channelId).then(() => {
                                  navigateReplace(`/rating/${store.channelId}`);
                                });
                              },
                            });
                          }}
                        >
                          상담 종료
                        </Button>
                      )}
                    </div>
                  </div>
                  {/* <div className={Styles.SubHeader} style={{ height: !store.isBottom ? 0 : 54 }}>
                    <div className={Styles.WorkTime}>
                      <FaRegClock />
                      <div className={Styles.SubTitle}>영업시간</div>
                      {storeInfo?.storeTime}
                    </div>
                    <div className={Styles.CloseDay}>
                      <BiCalendarEvent />
                      <div className={Styles.SubTitle}>휴점일</div>
                      <span>{(storeInfo?.storeClose || []).join(',\u00A0\u00A0') || '-'}</span>
                    </div>
                  </div> */}
                </div>
              );
            }}
            onCloseCallback={() => {
              navigateReplace(`/rating/${chatStore.channelId}`);
            }}
          />
        ) : (
          <div
            className={`d-flex justify-content-center align-items-center h-100 w-100`}
          >
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        )}
      </div>
    );
  }
}

export default ChatView;
