import React from 'react';
import { Header } from './Header.js';
import { Content } from './Content.js';
import { MessageInput } from './MessageInput.js';
import Styles from './Chat.module.scss';
import PropTypes from 'prop-types';
import { ChatStore } from '../../store/ChatStore.js';
import { observer } from 'mobx-react';
import { TICKET_STATUS } from '../../const/ChatConst.js';
import AgentTransferModal from './modal/AgentTransferModal.js';
import { ConfirmDialog } from '../modal/ConfirmDialog.js';
import { AlertDialog } from '../modal/AlertDialog.js';
import { CloseDialog } from './modal/CloseDialog.js';
import TeamTransferModal from './modal/TeamTransferModal.js';
import ResendDialog from './modal/ResendDialog.js';
import Recommend from './Recommend';

@observer
class Chat extends React.Component {
  render() {
    let {
      store,
      backButton,
      renderSubHeader,
      onCloseCallback,
      renderDropDownMenu,
      readOnly = false,
      renderCustomHeader,
      isImageSendAble,
      isFileSendAble,
      contentClassName,
      isUser,
    } = this.props;
    return (
      <>
        {store?.channelId && (
          <div className={Styles.Chat}>
            <Header
              store={store}
              backButton={backButton}
              readOnly={readOnly}
              renderDropDownMenu={renderDropDownMenu}
              renderCustomHeader={renderCustomHeader}
              onCloseCallback={onCloseCallback}
            />
            {renderSubHeader && renderSubHeader(store.channel)}
            <Content
              store={store}
              isUser={isUser}
              contentClassName={contentClassName}
            />
            {store.status != TICKET_STATUS.CLOSED && !readOnly && (
              <MessageInput
                isUser={isUser}
                store={store}
                isFileSendAble={isFileSendAble}
                isImageSendAble={isImageSendAble}
              />
            )}
            {!isUser && store.status === TICKET_STATUS.ACTIVE && (
              <Recommend store={store} />
            )}
            {store.isOpenClose && (
              <CloseDialog store={store} onCloseCallback={onCloseCallback} />
            )}
          </div>
        )}
        {store.rsocketStore?.isReady && (
          <>
            <AgentTransferModal store={store} />
            <TeamTransferModal store={store} />
          </>
        )}
        <ConfirmDialog />
        <AlertDialog />
        <ResendDialog store={store} />
      </>
    );
  }
}

Chat.defaultProps = {
  historyBackAble: false,
  readOnly: false,
  historyBackHandler: () => {},
};

Chat.propType = {
  store: PropTypes.instanceOf(ChatStore),
  callbackLeave: PropTypes.func,
  historyBackAble: PropTypes.bool,
  readOnly: PropTypes.bool,
  historyBackHandler: PropTypes.func,
  contentClassName: PropTypes.object,
};

export { Chat };
