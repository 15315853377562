import React from 'react';
import Styles from './Content.module.scss';
import MessageContent from './MessageContent.js';
import dayjs from 'dayjs';
import {
  BUTTON_TYPE,
  CONTENT_TYPE,
  USER_TYPE,
} from '../../../const/ChatConst.js';

const QuickReplyButton = ({ type, label, value, sessionId, store }) => {
  const sendMessage = () => {
    let user = store?.sender;
    if (user.type !== USER_TYPE.CUSTOMER) return;

    if (type === BUTTON_TYPE.DIALOG_FLOW) {
      let contentType = CONTENT_TYPE.DIALOG_FLOW_REQUEST;
      let message = {
        content: {
          type: contentType,
          sessionId,
          value: label,
          event: value,
        },
        contentType,
        createdAt: dayjs(),
      };
      store.onSendMessage(message);
    } else if (value && value.trim().length > 0) {
      const trimmedInputValue = value.trim();
      store.onSendMessage(trimmedInputValue);
    }
  };

  return (
    <button
      type="button"
      className={Styles.QuickReply}
      value={value}
      onClick={sendMessage}
    >
      {label}
    </button>
  );
};

class CustomContent extends React.Component {
  render() {
    let {
      value,
      store,
      isBubble,
      isIncoming,
      quickReplies,
      replyMessage,
      messageKey,
    } = this.props;
    let contents = value;
    return (
      <>
        <div
          className={`d-flex flex-column ${Styles.CustomContent} ${isIncoming ? Styles.In : Styles.Out} ${isBubble ? Styles.Bubble : ``}`}
        >
          {contents &&
            contents.map((content, idx) => {
              let isFirst = idx == 0 || !isBubble;
              let isLast = idx + 1 == contents.length || !isBubble;
              let renderReplyMessage = isFirst ? replyMessage : undefined;
              return (
                <div
                  key={`${messageKey}_${idx}`}
                  className={isBubble ? `` : `mb-2`}
                >
                  <MessageContent
                    content={{ ...content, isBubble: !isBubble }}
                    store={store}
                    isFirst={isFirst}
                    isLast={isLast}
                    isIncoming={isIncoming}
                    replyMessage={renderReplyMessage}
                  />
                </div>
              );
            })}
        </div>
        {quickReplies && quickReplies.length > 0 && (
          <div className={Styles.QuickReplies}>
            {quickReplies.map((buttonProps, idx) => (
              <QuickReplyButton
                key={`${buttonProps?.key}-quick-${idx}`}
                store={store}
                {...buttonProps}
              />
            ))}
          </div>
        )}
      </>
    );
  }
}

export { CustomContent };
