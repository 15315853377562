import React, { Component } from 'react';

import { Rating } from '@qbot-chat/qbot-uikit/chat';
import { chatStore, rSocket } from '../store/FrontChatStore';
import { goBack, navigate, navigateReplace } from '../helper/HistoryHelper';
import Styles from './RatingView.module.scss';
import { ArrowLeft2 } from 'iconsax-react';
import { action, makeObservable, observable } from 'mobx';
import BouncingDots from './loading/BouncingDots';
import { ReactComponent as Character } from './loading/character.svg';
import { MdClear } from 'react-icons/md';
import { observer } from 'mobx-react';
@observer
class RatingView extends Component {
  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @observable
  isLoad = false;

  @observable
  message =
    `새롭게 도입한 디지털 상담 시스템(챗봇/채팅)은 보다 많은 고객들의 문의를 신속하게 처리하기 위함입니다.${'\n'}고객님의 만족도는 몇 점입니까?`;

  @action.bound
  async init() {
    let {
      match: { params },
    } = this.props;
    chatStore.setChannel({ id: params.channelId });
  }

  componentDidMount() {
    this.isLoad = false;
    rSocket.requestChatApi(`/app/setting`).then(({ data }) => {
      this.message = data?.chatRatingMessage;
      if (!data.isEvaluationableByCustomer) {
        navigateReplace('/close');
      } else {
        this.isLoad = true;
        this.init().then();
      }
    });
  }

  render() {
    return (
      <div className={`d-flex flex-column flex-default overflow-hidden`}>
        {this.isLoad ? (
          <>
            <div className={Styles.Header}>
              <div
                className={Styles.UseButton}
                onClick={() => {
                  goBack();
                }}
              >
                <ArrowLeft2 size="32" color="var(--bs-secondary)" />
              </div>

              <p className={Styles.Label}>고객만족도조사</p>

              <div
                onClick={() => {
                  chatStore.setChannel(null);
                  navigate('/close?isRating=false');
                }}
                className={Styles.UseButton}
              >
                <MdClear size="32" color="var(--bs-secondary)" />
              </div>
            </div>
            <Rating
              store={chatStore}
              message={this.message}
              onClosed={() => {
                chatStore.setChannel(null);
                navigate('/close?isRating=true');
              }}
            />
          </>
        ) : (
          <div
            className={`h-100 w-100 d-flex flex-column justify-content-center align-items-center`}
          >
            <div
              className="d-flex justify-center"
              style={{ margin: '40px 0 16px 0' }}
            >
              <Character />
            </div>
            <div className="d-flex flex-column justify-center align-items-center">
              <div>잠시만 기다려 주세요.</div>
            </div>
            <div
              className="d-flex justify-center"
              style={{ margin: '11px 0 27px 0' }}
            >
              <BouncingDots />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default RatingView;
